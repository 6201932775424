body{
  position: relative;
  cursor: none;
}
#custom-cursor {
  position: fixed;
  width: 40px;
  height: 40px;
  //border: 1px solid #ffffff;
  //border-radius: 50%;
  //background-color: #ffffff;
  //mix-blend-mode: difference;
  pointer-events: none;
  z-index: 9999;
  top: 0;
  left: 0;
  //transform: translate(-50%, -50%);
  transform: translate(-10px, 0px);

  svg{
    font-size: 30px;
    /* Glow effect */
    filter: drop-shadow(0 0 5px rgba(139, 233, 245, 0.5));
  }

  #cursor-hint{
    position: absolute;
    top: 100%;
    left: 100%;
    padding: 10px 15px;
    width: max-content;

    background: linear-gradient(145deg, #8e2de2, #4a00e0);
    //background-color: linear-gradient(#8e2de2, #4a00e0);
    color: white;
    border-radius: 10px;
    border: 1px solid white;
  }
}