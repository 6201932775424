/* varibable */
$mobile: 768px;

*, *::before, *::after {
  font-family: 'Poppins', sans-serif;

  //@media screen and (max-width: $mobile) {
  //  font-size: 16px;
  //}
}



#home-page {
  padding: 5%;
  min-height: 100vh;
  //background-color: black;
  //background-image: url('../../public/imgs/wolf8.jpeg'), linear-gradient(-90deg ,rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9));
  background-image: url('../../public/imgs/wolf8.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1));
    //background-color: red;
  }
}

#avatar  {
  height: 90px;
  width: 90px;
  border: 1px solid rgb(59 130 246);
  //box-shadow: 0 0 10px #3b82f680, 0 0 150px #3b82f680;
  box-shadow: 0 0 100px 100px #ffffff;
}

#info-card {
  grid-column: span 4/ span 4;
  background-color: rgba(16, 50, 105, 0.5);
  filter: drop-shadow(0 0 7px rgb(59 130 246/.4));
  backdrop-filter: blur(40px);
  color: rgb(147 197 253);
  text-shadow: 0 0 10px #3b82f680, 0 0 150px #3b82f680;


  h4, h5, h6 {
    font-weight: 400;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  @media screen and (max-width: $mobile) {
    grid-column: span 6/ span 6;
    text-align: center;

    > div {
      flex-direction: column;
    }
  }
}

.social-card {
  grid-column: span 2/ span 2;
  transform: scale(1);
  transition: transform 0.3s ease;
  overflow: hidden;

  a{
    color: unset;
    text-decoration: unset;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    font-size: 50px;
  }

  a {
    transition: transform 0.3s ease;
    transform: rotateZ(0deg) scale(1);
  }

  &:hover {
    transform: scale(0.95);
    transition: transform 0.3s ease;

    a {
      transform: rotateZ(-10deg) scale(1.4);
      transition: transform 0.3s ease;
    }
  }

  @media screen and (max-width: $mobile) {
    grid-column: span 3/ span 3;
  }
}

.page-container {
  max-width: 750px;
  margin: 0 auto;
  z-index: 1000;
  position: relative;
}

.card-container {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 1.5rem;
}

#about-card {
  grid-column: span 4/ span 4;

  @media screen and (max-width: $mobile) {
    grid-column: span 6/ span 6;
  }
}

#timezone-card {
  grid-column: span 2/ span 2;

  background-color: rgb(49, 52, 101);

  @media screen and (max-width: $mobile) {
    grid-column: span 3/ span 3;
  }
}

#tech-stack-card{
  grid-column: span 4/ span 4;
  background-color: #A0E7E5;

  .tech-grid{
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(6, 1fr);

    svg{
      font-size: 30px;
    }
  }

  @media screen and (max-width: $mobile) {
    grid-column: span 6/ span 6;
  }
}