@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nanum+Gothic:wght@400;700;800&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*, *::before, *::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.youtube {
  // youtube logo color
  color: #FF0000;
  fill: #FF0000;
}

.link {
  // blue link color
  color: #1A73E8;
}

.github {
  // github logo color
  color: #000000;
}

.twitter {
  // twitter logo color
  color: #1DA1F2;
}

.facebook {
  // facebook logo color
  color: #3B5998;
}

.instagram {
  // instagram logo color
  color: #C13584;
}

.linkedin {
  // linkedin logo color
  color: #0077B5;
}

body {
  //font-family: 'Montserrat', sans-serif;
  //font-family: 'Nanum Gothic', sans-serif;
  //font-family: 'Open Sans', sans-serif;
  //font-family: 'Poppins', sans-serif;
  color: rgb(71, 85, 107);
  font-family: 'Open Sans', sans-serif;
  //line-height: 1.2;
  background-color: #FDFDFD;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Nanum Gothic', sans-serif;
}

p, div {
  font-size: 15px;
  //line-height: 1.3;
}

a {
  text-decoration: none;
  color: rgb(71, 85, 107);
}

hr {
  border: 1px solid #e4e5e7;
}

.button {
  background-color: white;
  border: 2px solid #E8E9EB;
  padding: 10px 20px;
  border-radius: 8px;
  color: rgb(52, 65, 84);
  margin: 8px 10px;
  cursor: pointer;

  &:hover {
    background-color: rgb(52, 65, 84);
    border-color: rgb(52, 65, 84);
    color: white;
  }
}

.button-dark {
  background-color: rgb(52, 65, 84);
  border: 2px solid rgb(52, 65, 84);
  color: white;

  &:hover {
    background-color: rgb(71, 85, 107);
    border: 2px solid #E8E9EB;
  }
}

.custom-card {
  background-color: white;
  border: 1px solid #e4e5e7;
  border-radius: 8px;
  padding: 20px 20px;
  //height: 100px;
  //box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
}

.avatar {
  img {
    height: 100%;
    //height: auto;
    width: 100%;
    //width: auto;
    border-radius: 50%;
    //border-radius: 8px;
    z-index: 1;
    position: relative;
  }

  height: 100px;
  width: 100px;
  border-radius: 50%;
  position: relative;
}

.avatar2 {
  img {
    height: 100%;
    //height: auto;
    //width: 100%;
    width: auto;
    //border-radius: 50%;
    border-radius: 8px;
    z-index: 1;
    position: relative;
  }

  height: 100px;
  //width: 100px;
  //border-radius: 50%;
  position: relative;
}

.avatar3 {
  img {
    //height: 100%;
    height: auto;
    width: 100%;
    //width: auto;
    //border-radius: 50%;
    border-radius: 8px;
    z-index: 1;
    position: relative;
  }

  height: 100px;
  //width: 100px;
  //border-radius: 50%;
  position: relative;
}

.white-border {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    transform-origin: center;
    transform: scale(1.1);
    border-radius: 50%;

    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.1);
  }
}

.section {
  padding: 15px 20px;

  @media screen and (max-width: 768px) {
    padding: 15px 0;
  }
}

.section-heading {
  padding-bottom: 15px;
}

.section-body {

}

.project-grid {
  display: grid;
  column-gap: 30px;
  row-gap: 30px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    //row-gap: 30px;
  }
}

.link-attachments {
  display: flex;
  margin-top: 15px;

  list-style: none;

  li {
    margin-right: 10px;
  }
}

.project-card {
  position: relative;
  z-index: 10;

  .project-header {
    display: flex;
    align-items: center;

    .project-title {
      margin-left: 10px;
    }

    h3 {
      margin-bottom: 3px;
    }
  }

  .project-logo {
    height: 45px;
    //width: 55px;
    width: auto;
  }
}

.education-card {
  //padding: 15px 20px;
  margin-bottom: 20px;

  .header {
    display: flex;
    align-items: flex-start;

    p {
      font-size: 14px;
    }

    .project-title {
      margin-left: 10px;
    }

    .title {
      margin-bottom: 3px;
    }
  }

  .project-logo {
    max-height: 40px;
    max-width: 40px;
    //height: 50px;
    //width: auto;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.gold {
  color: #FFD700;
}

.silver {
  color: #C0C0C0;
}

.bronze {
  color: #CD7F32;
}

.blue {
  color: #1E90FF;
}


.award-card {
  margin-bottom: 20px;

  .header {
    display: flex;
    align-items: flex-start;

    .award-logo {
      margin-right: 5px;
    }

    p {
      font-size: 14px;
    }

    .award-title {
      //margin-left: 10px;
      //font-size: 12px !important

      .title {
        font-weight: 500;;
        margin-bottom: 3px;
      }
    }
  }
}

.work-bond {
  border-left: 2px solid #e4e5e7;
  padding: 10px 0 10px 40px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: calc(50% + 7px);
    left: -7px;
    background-color: #e4e5e7;
    height: 2px;
    width: 100px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: -7px;
    background-color: #e4e5e7;
    height: 14px;
    width: 14px;
    border-radius: 50%;
  }

  ul {
    margin-left: 15px;

    @media screen and (max-width: 768px) {
      margin-left: 5px;
    }

    li {
      margin-bottom: 8px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 10px 0 10px 0;
    //margin-left: -10px;
    border-left: none;

    &:after, &:before {
      display: none;
    }
  }
}

footer {
  padding: 20px;
  background: linear-gradient(90deg, #43C6AC 0%, #F8FFAE 100%);
  color: black;
  text-align: center;
}

.side-card {
  margin: 20px 0;

  .side-card-header {
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 16px;
    display: flex;
    align-items: center;
  }

  .title {
    font-weight: 600;
    margin-bottom: 8px;
    font-size: 16px;
  }

  ul {
    list-style: none;
  }

  li {
    margin-bottom: 20px;
  }

  .location {
    display: flex;
    margin-top: 5px;
  }

  .avatar {
    display: inline-block;
    height: 25px;
    width: 25px;
  }
}

.cv-container {
  //background-color: red;
  height: 100vh;
  //width: 100vw;

  .banner {
    height: 200px;
    width: 100%;
    background: linear-gradient(90deg, #43C6AC 0%, #F8FFAE 100%);

    ul.navbar {
      list-style: none;
      display: flex;
      justify-content: flex-end;
      padding: 10px 20px;
      margin: 0;

      li {
        margin-left: 20px;

        a {
          color: black;
        }
      }

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  .banner-avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -60px;
    margin-bottom: 30px;
    padding: 0 30px;

    .avatar {
      height: 120px;
      width: 120px;

      margin: 0 auto 20px auto;
    }

    h2 {

    }

    h3 {
      margin-top: 10px;
      font-family: 'Nanum Gothic', sans-serif;
    }

    .avatar-cta {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 0px;
    }
  }

  .cv-body-container {
    max-width: 1200px;
    //padding: 0 30px;
    //background-color: red;
    margin: auto;
    display: flex;
    //font-family: Arial, Helvetica, sans-serif;

    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }

  .cv-body {
    //padding: 0 30px;
  }

  .cv-body-left {
    flex: 5;
    margin-right: 15px;
    //padding: 0 0 0 30px;
    //background-color: red;

    @media screen and (max-width: 768px) {
      margin-right: 0;
      flex: 1;

      padding: 0 20px;
    }
  }

  .cv-body-right {
    flex: 2;
    //background-color: black;

    @media screen and (max-width: 768px) {
      flex: 1;
      padding: 0 20px;
    }
  }


}

.mobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.desktop {
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
